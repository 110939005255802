export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const result = array.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
  return result;
};
