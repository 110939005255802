import { fontMap } from 'root/utils/OOI/consts';
import {
  ITEM_DESCRIPTION,
  ITEM_NAME,
  ITEM_PRICE,
  MENU_BACKGROUND,
  MENU_DESCRIPTION,
  MENU_NAME,
  SECTION_DESCRIPTION,
  SECTION_NAME,
  VARIANT_NAME,
  VARIANT_PRICE,
} from './consts';
import type {
  Color,
  DataItemRefArray,
  DataItemText,
  FontObject,
  MigrationComponent,
  OverriddenData,
  Styles,
  Theme,
} from './types';
import type { StyleRef } from '@wix/document-services-types';

type StyleContext = {
  fonts: FontObject[];
};

type StyleMap<T extends keyof Styles> = {
  id: string;
  style: T;
  getValue: ({
    styleItem,
    dataItem,
    context,
  }: {
    styleItem: OverriddenData;
    dataItem?: OverriddenData;
    context: StyleContext;
  }) => Styles[T] | undefined;
};

const getStyleRef = (item: OverriddenData) => {
  const values = (item.dataItem as DataItemRefArray).values;

  return values?.find((value) => value.type === 'ComponentStyle') as StyleRef;
};

const getTextFormatting = (textFormat: string) => {
  const regex = /<(\w+)\s+class="([^"]*?\bfont[^"]*?)"/;
  const match = textFormat.match(regex);

  if (match) {
    const htmlElement = match[1];
    const className = match[2];
    const themeName = fontMap[className] || 'Custom';

    return { htmlElement, themeName };
  }

  return {};
};

const getTheme = ({
  styleItem,
  dataItem,
  context,
}: {
  styleItem: OverriddenData;
  dataItem?: OverriddenData;
  context: StyleContext;
}) => {
  const componentStyle = getStyleRef(styleItem);

  const textFormat = (dataItem?.dataItem as DataItemText)?.text;

  const textFormatting = textFormat ? getTextFormatting(textFormat) : {};

  if (componentStyle) {
    const properties = componentStyle.style.properties;

    const fontSize = properties.fontSize as string | undefined;

    let fontFamily = properties.fontFamily as string | undefined;

    if (fontFamily) {
      const font = context.fonts.find((fontObject) => fontObject.cssFontFamily === fontFamily);

      if (font) {
        fontFamily = font.fontFamily;
      } else {
        const firstFontFamily = fontFamily.split(',')[0];

        if (firstFontFamily.startsWith('"') && firstFontFamily.endsWith('"')) {
          fontFamily = firstFontFamily.slice(1, -1);
        }
      }
    }

    const theme: Theme = {
      ...textFormatting,
      color: properties.color,
      fontSize: fontSize ? parseInt(fontSize.replace('px', ''), 10) : undefined,
      fontFamily,
      italic: properties.fontStyle === 'italic',
      bold: properties.fontWeight === 'bold',
      underline: properties.textDecoration === 'underline',
    };

    return theme;
  }

  return undefined;
};

const styleMap: StyleMap<keyof Styles>[] = [
  {
    id: MENU_BACKGROUND,
    style: 'menuBackgroundColor',
    getValue: ({ styleItem }) => {
      const componentStyle = getStyleRef(styleItem);

      if (componentStyle) {
        const properties = componentStyle.style.properties;

        const color: Color = {
          background: properties.bg,
          alpha: properties['alpha-bg'],
        };

        return color;
      }

      return undefined;
    },
  },
  {
    id: MENU_NAME,
    style: 'menuName',
    getValue: getTheme,
  },
  {
    id: MENU_DESCRIPTION,
    style: 'menuDescription',
    getValue: getTheme,
  },
  {
    id: SECTION_NAME,
    style: 'sectionName',
    getValue: getTheme,
  },
  {
    id: SECTION_DESCRIPTION,
    style: 'sectionDescription',
    getValue: getTheme,
  },
  {
    id: ITEM_NAME,
    style: 'itemName',
    getValue: getTheme,
  },
  {
    id: ITEM_DESCRIPTION,
    style: 'itemDescription',
    getValue: getTheme,
  },
  {
    id: ITEM_PRICE,
    style: 'itemPrice',
    getValue: getTheme,
  },
  {
    id: VARIANT_NAME,
    style: 'variantName',
    getValue: getTheme,
  },
  {
    id: VARIANT_PRICE,
    style: 'variantPrice',
    getValue: getTheme,
  },
];

export const getStyles = ({ component, allFonts }: { component: MigrationComponent; allFonts: FontObject[] }) => {
  const styles: Styles = {};
  const overriddenData: OverriddenData[] = component.serialize.custom.overriddenData;

  styleMap.forEach(({ id, style, getValue }) => {
    const styleItem = overriddenData.find(
      (overriddenItem) => overriddenItem.itemType === 'style' && overriddenItem.compId === id
    );

    const dataItem = overriddenData.find(
      (overriddenItem) => overriddenItem.itemType === 'data' && overriddenItem.compId === id
    );

    if (styleItem) {
      styles[style] = getValue({ styleItem, dataItem, context: { fonts: allFonts } });
    }
  });

  return styles;
};
