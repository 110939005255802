import type { WidgetBuilder } from '@wix/app-manifest-builder';
import type { FlowEditorSDK, EditorScriptFlowAPI, TFunction } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI
) => {
  const t = flowAPI.translations.t as TFunction;

  widgetBuilder.set({
    displayName: t('widget.columns.display-name'),
    nickname: 'SectionsWidget',
  });
};
