import type { BaseShowcasePopulatedItem } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { PopulatedItemClientFactory } from '@wix/restaurants-populated-item-client/web';

export const PopulatedItemsClient: HttpClientFactory<BaseShowcasePopulatedItem> = (httpClient) => {
  const populatedItemsClient = PopulatedItemClientFactory({
    httpClient,
    populationLevel: { labels: true, modifierGroups: false, modifiers: false, priceVariants: true },
  });
  return {
    get: async ({ id }) => {
      const response = await populatedItemsClient.getById(id);

      if (response.hasError) {
        throw new Error(`Error when calling getItem with id ${id}- ${response.error}`);
      }

      return { data: response.data };
    },
    getAll: async ({ ids, paging }) => {
      const response = await populatedItemsClient.getBulk({ ids, paging });

      if (response.hasError) {
        throw new Error(`Error when calling getItems- ${response.error}`);
      }

      return { data: response.data.data, pagingMetadata: response.data.pagingMetadata };
    },
  };
};
