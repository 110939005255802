export const ITEMS_WIDGET_COMPONENT_IDS = {
  itemsRepeater: '#itemsRepeater',
  itemTitle: '#itemTitle',
  itemDescription: '#itemDescription',
  itemPrice: '#itemPrice',
  itemCurrency: '#itemCurrency',
  itemImage: '#itemImage',
  itemLabelsLayouter: '#labelLayouter1',

  itemLabels: '#itemLabelsMultiStateBox',
  itemLabelsTextAndIconState: '#textAndIcon',

  itemDivider: '#itemDivider',
  itemPriceVariantsBox: '#itemPriceVariantsBox',
  itemPriceVariantsRepeater: '#itemPriceVariants',
} as const;

export const LABELS_STATES = {
  TEXT_AND_ICON: 'textAndIcon',
  ICON_ONLY: 'iconOnly',
  TEXT_ONLY: 'textOnly',
};

export const LABELS_LIMIT = 5;
export const PRICE_VARIANTS_LIMIT = 20;
export const UNSELECTED_ITEMS_COMPONENT_IDS = Object.values(ITEMS_WIDGET_COMPONENT_IDS);
