import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import type { MenuNavigationWidgetProps } from './types';

const DEFAULT_DATA: MenuNavigationWidgetProps = {
  navigationBarItems: [],
  menusMap: {},
  currentMenuId: '',
};

export default createBlocksModel({
  widgetName: 'Menu Navigation',
  props: {
    data: {
      // TODO: fix this when https://wix.slack.com/archives/CKDB50KE2/p1668693127598739 done
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: DEFAULT_DATA,
    },
  },
  methods: {
    // Here you can define your widget public props
    setInitMenuFn: {
      params: [{ name: 'initMenuFn', description: 'function that initializes the menu' }],
    },
    setSetEmptyMenuFn: {
      params: [{ name: 'setEmptyMenuStateFn', description: 'function that sets menu to empty state' }],
    },
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
