import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { NAVIGATION_WIDGET_ID } from 'root/panels/ElementsPanel/consts';

export const getRoleByCompRef = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  const connection = await editorSDK.controllers.listConnections('', {
    componentRef,
  });
  if (connection?.length) {
    return connection[0].role;
  }
};

export const getCompByRoles = async (
  editorSDK: EditorSDK,
  widgetRef: ComponentRef,
  roles: string[]
): Promise<{ componentRef: ComponentRef; role?: string }[]> => {
  const connections = await editorSDK.components.getChildren('', {
    componentRef: widgetRef,
    fromDocument: true,
    recursive: true,
  });
  const componentList = (
    await Promise.all(
      connections.map(async (componentRef) => {
        return {
          componentRef,
          role: await getRoleByCompRef(editorSDK, componentRef),
        };
      })
    )
  ).filter((ref) => {
    return roles.includes(ref.role as string);
  });

  return componentList;
};

export const showComp = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  await editorSDK.components.refComponents.expandReferredComponent('token', {
    componentRef,
  });
};

export const hideComp = async (editorSDK: EditorSDK, componentRef: ComponentRef) => {
  componentRef.id.includes(NAVIGATION_WIDGET_ID)
    ? await editorSDK.components.refComponents.collapseReferredComponent('token', { componentRef })
    : await editorSDK.components.remove('token', {
        componentRef,
      });
};
