import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { UNSELECTED_SECTIONS_COMPONENT_IDS } from 'root/utils/consts';
import { disableWidgetComponentSelection } from 'root/utils/manifestUtils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Section2',
    nickname: 'Section2Widget',
  });

  UNSELECTED_SECTIONS_COMPONENT_IDS.forEach((componentId) =>
    disableWidgetComponentSelection(widgetBuilder, componentId)
  );
};
