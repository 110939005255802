import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorType } from '@wix/platform-editor-sdk';
import { MENUS_COMP_REF_SUFFIX } from 'root/utils/consts';
import { BiReporter } from 'root/utils/BiReporter';
import { NAVIGATION_PANEL, NAVIGATION_PANEL_ORIGIN } from 'root/utils/bi';
import type { ConnectedComponentsBuilder, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getBlocksPanelOpener } from 'root/utils/commonUtils';

const openNavigationLayoutPanel = async (
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  navigationComponentRef: ComponentRef
) => {
  const t = flowAPI.translations.t;
  const editorType = flowAPI.environment.isClassicEditor ? EditorType.Classic : EditorType.Responsive;
  const openBlocksPanel = await getBlocksPanelOpener(editorSDK, editorType, flowAPI.essentials);
  const biReporter = new BiReporter(flowAPI.bi, navigationComponentRef.id);
  const ancestors = await editorSDK.components.getAncestors('', {
    componentRef: navigationComponentRef,
  });
  const menuRef = ancestors.find((comp) => comp.id.includes(MENUS_COMP_REF_SUFFIX));
  biReporter.reportMenuWidgetPanelBi(NAVIGATION_PANEL_ORIGIN, NAVIGATION_PANEL);
  menuRef && void openBlocksPanel('MenuTabs', menuRef, t('menu-navigation.panel.title'));
};

export const addNavigationPanelGfpp = async (
  componentBuilder: ConnectedComponentsBuilder,
  editorSDK: EditorSDK,
  flowAPI: EditorScriptFlowAPI,
  t: EditorScriptFlowAPI['translations']['t']
) => {
  componentBuilder
    .gfpp('mobile')
    .set('mainAction1', {
      label: t('menu-navigation.layout.button'),
      onClick: async (event) => {
        event.preventDefault();
        await openNavigationLayoutPanel(editorSDK, flowAPI, event.detail.componentRef);
      },
    })
    .set('settings', { behavior: 'HIDE' });
  componentBuilder
    .gfpp()
    .set('mainAction1', {
      label: t('menu-navigation.layout.button'),
      onClick: async (event) => {
        event.preventDefault();
        await openNavigationLayoutPanel(editorSDK, flowAPI, event.detail.componentRef);
      },
    })
    .set('settings', { behavior: 'HIDE' });
};

export const addLayoutPanelGfpp = (componentBuilder: ConnectedComponentsBuilder, editorSDK: EditorSDK) => {
  const openLayoutPanel = (componentRef: ComponentRef) =>
    editorSDK.editor.openNativeComponentPanel('', 'layout', {
      componentRef,
      // @ts-expect-error
      configuration: {
        controls: { subMenu: { hidden: true }, megaMenu: { hidden: true } },
      },
    });
  componentBuilder.gfpp().set('layout', {
    onClick: (event) => {
      event.preventDefault();
      openLayoutPanel(event.detail.componentRef);
    },
  });
  componentBuilder.gfpp('mobile').set('layout', {
    onClick: (event) => {
      event.preventDefault();
      openLayoutPanel(event.detail.componentRef);
    },
  });
};
