export const settleApiCall = async <T>(callback: () => Promise<T>) => {
  let error: Error | undefined;
  let data: T | undefined;

  try {
    data = await callback();
  } catch (callError) {
    error = callError as Error;
  }
  return { data, error };
};
