import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { ITEMS_PAGE_BM_URL, MENUS_SHOWCASE_APP_DEF_ID, MENUS_SHOWCASE_BM_URL } from './consts';
import { DashboardPage } from 'root/types';

export const openManageMenuDashboardPanel = async (
  editorSDK: FlowEditorSDK,
  dashboardPage: DashboardPage,
  referralInfo: string
) => {
  const baseUrl = dashboardPage === DashboardPage.items ? ITEMS_PAGE_BM_URL : MENUS_SHOWCASE_BM_URL;
  const url = `${baseUrl}?referralInfo=${referralInfo}`;

  editorSDK.editor
    .openDashboardPanel(MENUS_SHOWCASE_APP_DEF_ID, {
      url,
      closeOtherPanels: false,
    })
    .then(async () => {
      await editorSDK.document.application.livePreview.refresh('', {
        shouldFetchData: true,
        source: '',
      });
      await editorSDK.document.application.reloadManifest();
    });
};
