import type { ComponentRef, EditorSDK, StyleParam } from '@wix/platform-editor-sdk';
import type { MenusOrder } from 'root/components/Menu/types';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import type { PopulatedMenu } from 'root/types';
import type { BlocksSettings, Collapsed, DataItemImage, MigrationComponent, OverriddenData } from './types';
import { MENUS_COMP_REF_SUFFIX } from 'root/utils/consts';
import { BASE_MEDIA, DisplaySettings, displaySettingOptions } from './consts';
import type { AppController } from '@wix/document-services-types';

const isVisible = ({ dataItem }: { dataItem: unknown }) => {
  return (dataItem as Collapsed).ghost !== 'COLLAPSED';
};

export const getBlocksSettings = ({ component }: { component: MigrationComponent }) => {
  let mainProps: Partial<BlocksSettings> = {};

  const overriddenData: OverriddenData[] = component.serialize.custom.overriddenData;

  const data = overriddenData.find((item) => item.itemType === 'data' && item.compId === MENUS_COMP_REF_SUFFIX);

  if (data?.dataItem) {
    const mainSettings = JSON.parse((data.dataItem as AppController).settings ?? '{}');

    mainProps = mainSettings?.props ?? {};
  }

  const sideBySideImage = overriddenData.find((item) => item.itemType === 'data' && item.compId === `comp-lev3d87x`);

  if (sideBySideImage?.dataItem) {
    const imageUri = (sideBySideImage.dataItem as DataItemImage)?.image?.uri;

    mainProps.menusSideImage = imageUri;
  }

  const propsSettings: Partial<Record<DisplaySettings, boolean>> = {};

  const props = overriddenData.filter((item) => item.itemType === 'props' && !item.isMobile);

  displaySettingOptions.forEach(({ key, prop }) => {
    const propItem = props.find((item) => item.compId === key);

    if (propItem) {
      propsSettings[prop] = isVisible({ dataItem: propItem.dataItem });
    }
  });

  return { ...mainProps, ...propsSettings };
};

export const applyOoiSettings = async ({
  editorSDK,
  ref,
  settings,
  flowAPI,
}: {
  editorSDK: EditorSDK;
  ref: ComponentRef;
  settings: Partial<BlocksSettings>;
  flowAPI: EditorScriptFlowAPI;
}) => {
  const ooiStyleParams: StyleParam[] = [];
  let menus: PopulatedMenu[] | undefined;

  const getMenus = async () => {
    const { data } = await getMonitoredApiCall(
      () => PopulatedMenuClient(flowAPI.essentials.httpClient).getAll({}),
      flowAPI.reportError
    );
    return data?.data;
  };

  Object.entries(settings).forEach(async ([key, value]) => {
    switch (key) {
      case 'menusOrder':
        if (!menus) {
          menus = await getMenus();
        }

        const showAllMenus = settings.menusDisplayOption === 'all';

        const visibleMenus = (value as MenusOrder)
          .filter((id) => menus?.some((menu) => menu.id === id))
          .map((id) => ({ id, checked: true }));
        const hiddenMenus =
          menus
            ?.filter((menu) => !visibleMenus.some((visibleMenu) => visibleMenu.id === menu.id))
            .map((menu) => ({ id: menu.id as string, checked: showAllMenus })) || [];

        await editorSDK.tpa.data.set('', {
          compRef: ref,
          key: 'menusDisplayOrder',
          value: JSON.stringify([...visibleMenus, ...hiddenMenus]),
          scope: 'COMPONENT',
        });

        break;

      case 'menusDisplayOption':
        await editorSDK.tpa.data.set('', {
          compRef: ref,
          key: 'menusDisplayOption',
          value: value as string,
          scope: 'COMPONENT',
        });
        break;

      case 'shouldDisplayCurrency':
        ooiStyleParams.push({
          key: 'showItemCurrency',
          param: {
            value: !!value,
          },
          type: 'boolean',
        });
        break;

      case 'shouldDisplayVariantCurrency':
        ooiStyleParams.push({
          key: 'showItemVariantsCurrency',
          param: {
            value: !!value,
          },
          type: 'boolean',
        });
        break;

      case 'placeholderImageDisplayValue':
        if (value !== 'never') {
          await editorSDK.tpa.data.set('', {
            compRef: ref,
            key: 'showImagePlaceholder',
            value: true,
            scope: 'COMPONENT',
          });
        }
        break;

      case 'zeroPriceDisplayOption':
        if (value === 'dont show') {
          await editorSDK.tpa.data.set('', {
            compRef: ref,
            key: 'showZeroPrice',
            value: false,
            scope: 'COMPONENT',
          });
        }
        break;

      case 'placeholderImage':
        await editorSDK.tpa.data.set('', {
          compRef: ref,
          key: 'imagePlaceholder',
          value: JSON.stringify({ url: value }),
          scope: 'COMPONENT',
        });
        break;

      case 'menusSideImage':
        await editorSDK.tpa.data.set('', {
          compRef: ref,
          key: 'menusSideImage',
          value: JSON.stringify({ url: `${BASE_MEDIA}${value}` }),
          scope: 'COMPONENT',
        });
        break;

      default:
        if (Object.keys(DisplaySettings).includes(key)) {
          ooiStyleParams.push({
            key,
            param: {
              value: !!value,
            },
            type: 'boolean',
          });
        }

        break;
    }
  });

  return { ooiStyleParams };
};
