import type { WidgetDesignTabsArray } from 'root/types';
import { PRICE_VARIANTS_WIDGET_COMPONENT_IDS } from 'root/components/Varaint/consts';
import { MENU_WIDGET_COMPONENT_IDS, SECTION_WIDGET_COMPONENT_IDS } from './consts';
import { ITEMS_WIDGET_COMPONENT_IDS } from 'root/components/Items/consts';
import { LABELS_WIDGET_COMPONENT_IDS } from 'root/components/Label Layouter/consts';

const LABELS_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.labels',
    roles: [LABELS_WIDGET_COMPONENT_IDS.badge],
  },
];

const PRICE_VARIANTS_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.variant-name',
    roles: [PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantName],
  },
  {
    label: 'design-panel.tabs.variant-price',
    roles: [PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantPrice],
  },
];

const SECTIONS_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.section-title',
    roles: [SECTION_WIDGET_COMPONENT_IDS.sectionTitle],
  },
  {
    label: 'design-panel.tabs.section-description',
    roles: [SECTION_WIDGET_COMPONENT_IDS.sectionDescription],
  },
];

const ITEMS_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.item-title',
    roles: [ITEMS_WIDGET_COMPONENT_IDS.itemTitle],
  },
  {
    label: 'design-panel.tabs.item-description',
    roles: [ITEMS_WIDGET_COMPONENT_IDS.itemDescription],
  },
  {
    label: 'design-panel.tabs.item-price',
    roles: [ITEMS_WIDGET_COMPONENT_IDS.itemPrice],
  },
  {
    label: 'design-panel.tabs.item-image',
    dependents: [ITEMS_WIDGET_COMPONENT_IDS.itemImage],
    roles: [ITEMS_WIDGET_COMPONENT_IDS.itemImage],
  },
];

const MENU_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.widget-background',
    roles: [MENU_WIDGET_COMPONENT_IDS.menuContainer],
  },
  {
    label: 'design-panel.tabs.horizontal-menu',
    roles: [MENU_WIDGET_COMPONENT_IDS.navigationBar, MENU_WIDGET_COMPONENT_IDS.navigationWidgetTabs],
  },
  {
    label: 'design-panel.tabs.menu-title',
    roles: [MENU_WIDGET_COMPONENT_IDS.menuTitle],
  },
  {
    label: 'design-panel.tabs.menu-description',
    roles: [MENU_WIDGET_COMPONENT_IDS.menuDescription],
  },
];

const DIVIDER_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.section-divider',
    roles: [SECTION_WIDGET_COMPONENT_IDS.sectionDivider],
  },
  {
    label: 'design-panel.tabs.item-divider',
    roles: [ITEMS_WIDGET_COMPONENT_IDS.itemDivider],
  },
  {
    label: 'design-panel.tabs.variant-divider',
    roles: [PRICE_VARIANTS_WIDGET_COMPONENT_IDS.variantDivider],
  },
];

const MENU_DESIGN_PANEL_TABS_WITH_NAVIGATION_LAYOUTS = [
  ...MENU_DESIGN_PANEL_TABS.slice(0, 1),
  {
    label: 'design-panel.tabs.navigation-dropdown',
    roles: [MENU_WIDGET_COMPONENT_IDS.navigationWidgetDropdown],
    tooltip: 'design-panel.tabs.navigation-dropdown.info',
  },
  {
    label: 'design-panel.tabs.navigation-tabs',
    roles: [MENU_WIDGET_COMPONENT_IDS.navigationWidgetTabs],
    tooltip: 'design-panel.tabs.navigation-tabs.info',
  },
  ...MENU_DESIGN_PANEL_TABS.slice(2, MENU_DESIGN_PANEL_TABS.length),
];

export const getMenuDesignPanelTabs = (isConvertNavigationBarToWidgetExperimentEnabled?: boolean) => [
  ...(isConvertNavigationBarToWidgetExperimentEnabled
    ? MENU_DESIGN_PANEL_TABS_WITH_NAVIGATION_LAYOUTS
    : MENU_DESIGN_PANEL_TABS),
  ...SECTIONS_DESIGN_PANEL_TABS,
  ...ITEMS_DESIGN_PANEL_TABS,
  ...PRICE_VARIANTS_DESIGN_PANEL_TABS,
  ...LABELS_DESIGN_PANEL_TABS,
  ...DIVIDER_TABS,
];
