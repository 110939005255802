import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MENUS_COMP_REF_SUFFIX } from 'root/utils/consts';

export const getMenusComponentRef = async (compRef: ComponentRef, document: FlowEditorSDK['document']) => {
  const parentsRef = await document.components.getAncestors('', { componentRef: compRef });
  const menusComponentRef = parentsRef.find((componentRef) => componentRef.id.includes(MENUS_COMP_REF_SUFFIX));

  return menusComponentRef;
};
