import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { MENU_NAVIGATION_COMPONENT_IDS } from './consts';
import { getRole } from 'root/utils/manifestUtils';
import type { ConnectedComponentsBuilder } from '@wix/app-manifest-builder';
import { addLayoutPanelGfpp, addNavigationPanelGfpp } from './utils';

export const getWidgetManifest: GetWidgetManifestFn = async (widgetBuilder, editorSDK, flowAPI) => {
  widgetBuilder.set({
    displayName: 'Widget Menu Navigation',
    nickname: 'Menu NavigationWidget',
  });

  const experiments = flowAPI.experiments;
  const t = flowAPI.translations.t;

  const isConvertNavigationBarToWidgetExperimentEnabled = experiments.enabled(
    'specs.restaurants.convertNavigationBarToWidget'
  );

  const setNavigationWidgetName = (componentBuilder: ConnectedComponentsBuilder) => {
    componentBuilder.set({ displayName: t('menu-navigation.element.label') });
  };

  if (isConvertNavigationBarToWidgetExperimentEnabled) {
    widgetBuilder.configureConnectedComponents(
      getRole(MENU_NAVIGATION_COMPONENT_IDS.tabs),
      async (componentBuilder) => {
        setNavigationWidgetName(componentBuilder);
        await addNavigationPanelGfpp(componentBuilder, editorSDK, flowAPI, t);
        addLayoutPanelGfpp(componentBuilder, editorSDK);
      }
    );

    widgetBuilder.configureConnectedComponents(getRole(MENU_NAVIGATION_COMPONENT_IDS.dropdown), (componentBuilder) => {
      setNavigationWidgetName(componentBuilder);
      addNavigationPanelGfpp(componentBuilder, editorSDK, flowAPI, t);
      componentBuilder.gfpp('mobile').set('mainAction2', { behavior: 'HIDE' });
      componentBuilder.gfpp().set('mainAction2', { behavior: 'HIDE' });
    });
  }
};
