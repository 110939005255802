import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { disableWidgetComponentSelection } from 'root/utils/manifestUtils';
import { UNSELECTED_ITEMS_COMPONENT_IDS } from './consts';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Dish',
    nickname: 'DishWidget',
  });

  UNSELECTED_ITEMS_COMPONENT_IDS.forEach((componentId) => disableWidgetComponentSelection(widgetBuilder, componentId));
};
