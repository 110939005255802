import { getMenu, listMenus } from '@wix/ambassador-restaurants-menus-v1-menu/http';

import type { Menu, MenusPagingMetadata } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { getEntityChunks, sortByCreatedDate } from '@wix/restaurants-normalizers';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER } from 'root/api/consts';

export const MenusClient: HttpClientFactory<Menu> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getMenu({ menuId: id }))
      .then((res) => {
        const {
          data: { menu = {} },
        } = res;

        return { data: menu as Menu };
      })
      .catch((e) => {
        throw new Error(`Error when calling getMenu with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging, onlyVisible }) => {
    try {
      let idChunks: string[][] | undefined;
      let menus: Menu[] = [];
      let pagingMetadata: MenusPagingMetadata | undefined;
      if (ids?.length) {
        idChunks = getEntityChunks(ids, MAX_NUMBER_OF_ENTITIES_FROM_SERVER);
        const menusInChunks = await Promise.all(
          idChunks.map(async (chunk) => {
            const { data } = await httpClient.request(
              listMenus({
                menuIds: chunk || [],
                onlyVisible,
              })
            );
            return data.menus || [];
          })
        );
        menus.push(...menusInChunks.flatMap((menu) => menu as Menu[]));
      } else {
        const { data } = await httpClient.request(listMenus({ paging, onlyVisible }));
        menus = data.menus as Menu[];
        pagingMetadata = data.pagingMetadata;
      }
      return {
        data: (sortByCreatedDate(menus as (Menu & { createdDate: Date })[]) as Menu[]) || [],
        pagingMetadata,
      };
    } catch (error) {
      throw new Error(`Error when calling getMenus- ${error}`);
    }
  },
});
