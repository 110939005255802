import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import type { LabelsWidgetProps } from './types';

const DEFAULT_DATA: LabelsWidgetProps = {
  labels: [],
};

export default createBlocksModel({
  widgetName: 'Label Layouter',
  props: {
    data: {
      // TODO: fix this when https://wix.slack.com/archives/CKDB50KE2/p1668693127598739 done
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: DEFAULT_DATA,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
