import type { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { NAVIGATION_WIDGET_ID } from 'root/components/Menu Navigation/consts';
import { BiReporter } from 'root/utils/BiReporter';
import { isMobileViewport, setWidgetProps, getWidgetProps } from 'root/utils/commonUtils';
import { MENUS_COMP_REF_SUFFIX } from 'root/utils/consts';
import {
  MENU_PRESET_ID_TO_PRESET_NAME_MAP,
  MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP,
  MENU_DESKTOP_PRESET_ID_TO_MOBILE_PRESET_ID_MAP,
} from 'root/utils/widgetPresets';

export const initPresetChangedEventListener = async ({
  editorSDK,
  flowAPI,
}: {
  editorSDK: FlowEditorSDK;
  flowAPI: EditorScriptFlowAPI;
}) => {
  await editorSDK.addEventListener('presetChanged', async (event) => {
    const { presetId, componentRef } = event.detail;
    const isMobile = await isMobileViewport(editorSDK);
    const isChangingMobilePreset = !!componentRef.variants;
    if (componentRef.id.includes(NAVIGATION_WIDGET_ID)) {
      const ancestors = await editorSDK.components.getAncestors('', {
        componentRef,
      });
      const menuWidgetCompRef = ancestors.find((compRef) => compRef.id.includes(MENUS_COMP_REF_SUFFIX));

      const { shouldSeparateWebMobileNavigationPresets } =
        (menuWidgetCompRef && (await getWidgetProps(editorSDK, menuWidgetCompRef))) || {};

      if (!isMobile && !isChangingMobilePreset && !shouldSeparateWebMobileNavigationPresets) {
        editorSDK.application.appStudioWidgets.changePreset('', {
          componentRef,
          stylePresetId: presetId,
          layoutPresetId: presetId,
          context: {
            viewport: 'MOBILE',
          },
        });
      }

      if (isMobile && !shouldSeparateWebMobileNavigationPresets && menuWidgetCompRef) {
        setWidgetProps(editorSDK, menuWidgetCompRef, { shouldSeparateWebMobileNavigationPresets: true });
      }
    }
  });

  await editorSDK.addEventListener('globalDesignPresetChanged', async (event) => {
    const { preset: presetId, componentRef } = event.detail;
    const { mobilePreset } = (await getWidgetProps(editorSDK, componentRef)) || {};
    const biReporter = new BiReporter(flowAPI.bi, componentRef.id);
    await biReporter.init({ editorSDK, reportError: flowAPI.reportError });
    const isMobile = await isMobileViewport(editorSDK);
    const TOKEN = '';
    const PRESET_ID_TO_NAME_MAP = isMobile
      ? MENU_PRESET_ID_TO_PRESET_NAME_MOBILE_MAP
      : MENU_PRESET_ID_TO_PRESET_NAME_MAP;
    type PresetId = keyof typeof PRESET_ID_TO_NAME_MAP;

    const chosenPreset = PRESET_ID_TO_NAME_MAP[presetId as PresetId];

    const [parentRefComponent] = await editorSDK.components.getAncestors(TOKEN, {
      componentRef,
    });

    const mobilePresetId =
      MENU_DESKTOP_PRESET_ID_TO_MOBILE_PRESET_ID_MAP[
        presetId as keyof typeof MENU_DESKTOP_PRESET_ID_TO_MOBILE_PRESET_ID_MAP
      ] || presetId;

    if (presetId && parentRefComponent) {
      const shouldChangeMobilePreset = !isMobile && !mobilePreset;
      const changePresetPromisesArr = [
        editorSDK.application.appStudioWidgets.changePreset(TOKEN, {
          componentRef: parentRefComponent,
          stylePresetId: presetId,
          layoutPresetId: presetId,
        }),
        shouldChangeMobilePreset &&
          editorSDK.application.appStudioWidgets.changePreset(TOKEN, {
            componentRef: parentRefComponent,
            stylePresetId: mobilePresetId,
            layoutPresetId: mobilePresetId,
            context: {
              viewport: 'MOBILE',
            },
          }),
      ];
      Promise.all(changePresetPromisesArr);

      await editorSDK.document.components.refComponents.removeOverrides(TOKEN, {
        componentRef: parentRefComponent,
        inclusions: { variables: '*' },
      });

      const widgetProps = isMobile ? { mobilePreset: chosenPreset } : { preset: chosenPreset };
      await setWidgetProps(editorSDK, componentRef, widgetProps);

      biReporter.reportMenuLayoutPickBi(chosenPreset);
    }
  });
};
