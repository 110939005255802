import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const initDeviceSwitchEventListener = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const reloadManifest = async () => {
    await editorSDK.document.application.reloadManifest();
  };

  editorSDK.addEventListener('switchedToMobileView', reloadManifest);
  editorSDK.addEventListener('switchedToDesktopView', reloadManifest);
};
