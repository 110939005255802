import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { openManageMenuDashboardPanel } from 'root/utils/OOI/panels';
import type { BiReferral } from 'root/utils/OOI/bi';
import { PagesPanelTabType } from '@wix/platform-editor-sdk';
import { createMenuOOIPage } from 'root/utils/OOI/editorScript/shared/createPage';

export const privateOOIExports = (editorSDK: FlowEditorSDK) => {
  return {
    openManageMenuDashboardPanel: async (queryReferralInfo?: BiReferral) => {
      await openManageMenuDashboardPanel(editorSDK, queryReferralInfo);
    },
  };
};

export const createAdditionalMenuPage = async ({
  editorSDK,
  t,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: FlowEditorSDK;
  t: TFunction;
  tpaAppId: number;
  isResponsive: boolean;
}) => {
  const pageRef = await createMenuOOIPage({
    editorSDK,
    t,
    tpaAppId,
    isResponsive,
  });
  await editorSDK?.editor.openPagesPanel('', {
    pageRef,
    renameEnabled: true,
    initialSettingsTabType: PagesPanelTabType.PageInfo,
  });
};
