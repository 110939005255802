import type { PanoramaClientForComponent } from '@wix/fe-essentials-viewer-platform/panorama-client';

export class PanoramaLogger {
  constructor(private panorama?: PanoramaClientForComponent) {}

  menusShowcaseFirstInstallStarted = () => this.panorama?.transaction('menus-showcase-first-install').start();
  menusShowcaseFirstInstallEnded = () => this.panorama?.transaction('menus-showcase-first-install').finish();

  openMenuElementsPanelStarted = () => this.panorama?.transaction('open-menu-elements-panel').start();
  openMenuElementsPanelEnded = () => this.panorama?.transaction('open-menu-elements-panel').finish();

  openMenuSettingsPanelStarted = () => this.panorama?.transaction('open-menu-settings-panel').start();
  openMenuSettingsPanelEnded = () => this.panorama?.transaction('open-menu-settings-panel').finish();

  openSettingsPanelStarted = () => this.panorama?.transaction('open-settings-panel').start();
  openSettingsPanelEnded = () => this.panorama?.transaction('open-settings-panel').finish();

  setMenusDataStarted = () => this.panorama?.transaction('set-menus-data').start();
  setMenusDataEnded = () => this.panorama?.transaction('set-menus-data').finish();

  loadMenusPageStarted = () => this.panorama?.transaction('load-menus-page').start();
  loadMenusPageEnded = () => this.panorama?.transaction('load-menus-page').finish();
}
