import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { type PageRef } from '@wix/platform-editor-sdk';
import { PAGE_DATA } from 'root/utils/OOI/consts';

export const setPagesState = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');

  const dupPages = applicationPages.filter((page) => page.tpaPageId === PAGE_DATA.pageId);

  editorSDK.document.pages.setState('', {
    state: {
      [PAGE_DATA.pageId]: dupPages.map((page) => ({ id: page.id })) as PageRef[],
    },
  });
};
