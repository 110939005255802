import {
  CARD_MOBILE_PRESET_ID,
  GRID_PRESET_ID,
  LIST_1_COLUMN_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
  SIDE_BY_SIDE_2_COLUMN_PRESET_ID,
} from 'root/utils/consts';
import { getLabelCompElementIds } from 'root/utils/labels';

export const NAVIGATION_WIDGET_ID = 'comp-lwj05b7b_r_comp-lwizk5fu1';

export const MULTIPLE_MENUS_ELEMENTS_IDS = {
  navigationBar: 'navigationBar',
  navigationWidget: 'menuNavigationWidget',
};

export const ITEM_ELEMENTS_IDS = {
  itemDescription: 'itemDescription',
  itemPrice: 'itemPrice',
  itemCurrency: 'itemCurrency',
  itemLabels: 'itemLabels',
  itemDivider: 'itemDivider',
  itemImage: 'itemImage',
};

export const LABEL_ELEMENTS_IDS = {
  label: 'itemLabelsMultiStateBox',
  text: 'labeltext1',
  icon: 'labelicon1',
};

export const LABEL_REPEATER_ELEMENTS_IDS = {
  label: 'labelsLayouter',
};

export const MENU_ELEMENTS_IDS = {
  menuTitle: 'menuTitle',
  menuDescription: 'menuDescription',
  menuImage: 'menuImage',
  horizontalMenu: 'horizontalMenu',
};

export const SECTION_ELEMENTS_IDS = {
  sectionTitle: 'sectionTitle',
  sectionDescription: 'sectionDescription',
  sectionDivider: 'sectionDivider',
};

export const PRICE_VARIANTS_ELEMENTS_IDS = {
  variantName: 'variantName',
  variantPrice: 'variantPrice',
  variantCurrency: 'variantCurrency',
  variantDivider: 'variantDivider',
};

export const MULTIPLE_ROLES_REPRESENTATION: Record<string, string[]> = {
  [LABEL_ELEMENTS_IDS.text]: getLabelCompElementIds('labeltext'),
  [LABEL_ELEMENTS_IDS.icon]: getLabelCompElementIds('labelicon'),
  [LABEL_ELEMENTS_IDS.label]: [
    LABEL_ELEMENTS_IDS.label,
    LABEL_REPEATER_ELEMENTS_IDS.label,
    ...getLabelCompElementIds('labeltext'),
    ...getLabelCompElementIds('labelicon'),
  ],
  [LABEL_REPEATER_ELEMENTS_IDS.label]: [
    LABEL_ELEMENTS_IDS.label,
    LABEL_REPEATER_ELEMENTS_IDS.label,
    ...getLabelCompElementIds('labeltext'),
    ...getLabelCompElementIds('labelicon'),
  ],
  [MULTIPLE_MENUS_ELEMENTS_IDS.navigationBar]: [
    MULTIPLE_MENUS_ELEMENTS_IDS.navigationBar,
    MULTIPLE_MENUS_ELEMENTS_IDS.navigationWidget,
  ],
  [MULTIPLE_MENUS_ELEMENTS_IDS.navigationWidget]: [
    MULTIPLE_MENUS_ELEMENTS_IDS.navigationBar,
    MULTIPLE_MENUS_ELEMENTS_IDS.navigationWidget,
  ],
};

export const ROLES_DEPENDENCIES = {
  [LABEL_ELEMENTS_IDS.text]: {
    dependentRole: LABEL_ELEMENTS_IDS.icon,
    parentRole: LABEL_ELEMENTS_IDS.label,
  },
  [LABEL_ELEMENTS_IDS.icon]: {
    dependentRole: LABEL_ELEMENTS_IDS.text,
    parentRole: LABEL_ELEMENTS_IDS.label,
  },
};

const FILTERED_ELEMENTS_GROUPS = {
  LIST: [
    MENU_ELEMENTS_IDS.menuImage,
    SECTION_ELEMENTS_IDS.sectionDivider,
    ITEM_ELEMENTS_IDS.itemImage,
    PRICE_VARIANTS_ELEMENTS_IDS.variantDivider,
  ],
  GRID: [
    MENU_ELEMENTS_IDS.menuImage,
    SECTION_ELEMENTS_IDS.sectionDivider,
    PRICE_VARIANTS_ELEMENTS_IDS.variantDivider,
    ITEM_ELEMENTS_IDS.itemDivider,
  ],
  STRIP: [MENU_ELEMENTS_IDS.menuImage, PRICE_VARIANTS_ELEMENTS_IDS.variantDivider],
  COMMON: [SECTION_ELEMENTS_IDS.sectionDivider, ITEM_ELEMENTS_IDS.itemImage],
};

export const ELEMENT_DATA_BLACK_LIST_MAP = {
  [LIST_1_COLUMN_PRESET_ID]: [
    ...FILTERED_ELEMENTS_GROUPS.COMMON,
    MENU_ELEMENTS_IDS.menuImage,
    ITEM_ELEMENTS_IDS.itemDivider,
  ],
  [LIST_2_COLUMN_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.LIST,
  [LIST_3_COLUMN_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.LIST,
  [SIDE_BY_SIDE_1_COLUMN_PRESET_ID]: [...FILTERED_ELEMENTS_GROUPS.COMMON, PRICE_VARIANTS_ELEMENTS_IDS.variantDivider],
  [SIDE_BY_SIDE_2_COLUMN_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.COMMON,
  [LIST_1_COLUMN_WITH_IMAGE_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.STRIP,
  [GRID_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.GRID,
  [LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.LIST,
  [LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.STRIP,
  [CARD_MOBILE_PRESET_ID]: FILTERED_ELEMENTS_GROUPS.GRID,
};
