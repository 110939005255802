import type { ComponentRef, PageRef, Layout } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { PAGE_DATA, PARENT_APP_DEF_ID } from './consts';
import { createSectionDefinition } from './createSectionDef';
import { createWidgetDefinition } from './createWidgetDef';

type PageData = {
  pageId: string;
  title: string;
  widgetId: string;
  desktopPresetId: string;
  mobilePresetId: string;
  pageUriSEO: string;
};

const addWidgetAsPage = async ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  hidePage = false,
}: {
  editorSDK: FlowEditorSDK;
  isResponsive: boolean;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  hidePage?: boolean;
}): Promise<PageRef> => {
  const { pageId, widgetId, desktopPresetId, mobilePresetId, pageUriSEO } = pageData;
  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const pageRef = await editorSDK.document.pages.add('', {
      title: pageTitle,
      definition: {
        id: '',
        type: 'Page',
        components: isResponsive
          ? [
              createSectionDefinition([
                createWidgetDefinition({
                  appDefinitionId: appDefId,
                  widgetId,
                  desktopPresetId,
                  mobilePresetId,
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ]) as any,
            ]
          : [],
        data: {
          appDefinitionId: appDefId,
          managingAppDefId: PARENT_APP_DEF_ID,
          tpaPageId: pageId,
          pageUriSEO,
          hidePage,
        },
        breakpoints: isResponsive
          ? {
              type: 'BreakpointsData',
              values: [
                {
                  type: 'BreakpointRange',
                  id: 'breakpoints-kc1s7zda1',
                  min: 320,
                  max: 1000,
                },
                {
                  type: 'BreakpointRange',
                  id: 'breakpoints-kc1s7zda',
                  min: 320,
                  max: 750,
                },
              ],
            }
          : undefined,
        componentType: 'mobile.core.components.Page',
      },
      shouldAddMenuItem,
      shouldNavigateToPage,
    });
    return pageRef;
  });
  return pageRefPromise;
};

export const setPagesState = async ({ editorSDK }: { editorSDK: FlowEditorSDK }) => {
  const applicationPages = await editorSDK.document.pages.data.getAll('');

  applicationPages.forEach((page) => {
    if (page.tpaPageId === PAGE_DATA.pageId) {
      editorSDK.document.pages.setState('', {
        state: {
          [page.tpaPageId!]: [{ id: page.id! }] as PageRef[],
        },
      });
    }
  });
};

export const createAppPage = async ({
  editorSDK,
  isResponsive,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  hidePage = false,
}: {
  editorSDK: FlowEditorSDK;
  isResponsive: boolean;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  hidePage?: boolean;
}): Promise<{ pageRef: PageRef; widgetRef?: ComponentRef }> => {
  let widgetRef: ComponentRef | undefined;
  const ppPageRef = await addWidgetAsPage({
    editorSDK,
    isResponsive,
    appDefId,
    pageData,
    pageTitle,
    shouldAddMenuItem,
    shouldNavigateToPage,
    hidePage,
  });

  if (!isResponsive) {
    await editorSDK.document.transactions.runAndWaitForApproval('', async () => {
      widgetRef = await addWidgetWithPresets({ editorSDK, ppPageRef, pageData });
    });
  }

  await setPagesState({ editorSDK });

  return { pageRef: ppPageRef, widgetRef };
};

const addWidgetWithPresets = async ({
  editorSDK,
  ppPageRef,
  pageData,
}: {
  editorSDK: FlowEditorSDK;
  ppPageRef: PageRef;
  pageData: PageData;
}): Promise<ComponentRef> => {
  const { widgetId, desktopPresetId, mobilePresetId } = pageData;

  const pageChildren = await editorSDK.components.getChildren('', {
    componentRef: ppPageRef as ComponentRef,
  });

  const containerRef = pageChildren[0];

  const widgetRef = await editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    layout: {
      // NOTE: docked is being used to stretch the widget to the full width of the page
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      height: 100,
      width: 980,
      x: 0,
      y: 0,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as Layout & { docked: any },
    layouts: {
      componentLayout: {
        minHeight: {
          type: 'px',
          value: 280,
        },
        hidden: false,
        height: {
          type: 'auto',
        },
        type: 'ComponentLayout',
        width: {
          type: 'auto',
        },
      },
    },
    scopedPresets: {
      desktop: { layout: desktopPresetId, style: desktopPresetId },
      mobile: { layout: mobilePresetId, style: mobilePresetId },
    },
    installationType: 'closed',
    containerRef: containerRef || (ppPageRef as ComponentRef),
  });
  return widgetRef;
};
