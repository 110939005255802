export const MENU_NAVIGATION_COMPONENT_IDS = {
  tabs: '#navigationWidgetTabs',
  dropdown: '#navigationWidgetDropdown',
};

export const MENU_NAVIGATION_PRESETS = {
  tabsLeft: 'tabsLeft',
  tabsRight: 'tabsRight',
  tabsCenter: 'tabsCenter',
  dropdownRight: 'dropdownRight',
  dropdownLeft: 'dropdownLeft',
  dropdownCenter: 'dropdownCenter',
};

export const NAVIGATION_WIDGET_ID = 'comp-lwj05b7b';
