import { getSection, listSections } from '@wix/ambassador-restaurants-menus-v1-section/http';
import type { Section, SectionsPagingMetadata } from 'root/types';
import type { HttpClientFactory } from './utils/types';
import { getEntityChunks, sortByCreatedDate } from '@wix/restaurants-normalizers';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER } from 'root/api/consts';

export const SectionsClient: HttpClientFactory<Section> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getSection({ sectionId: id }))
      .then((res) => {
        const {
          data: { section = {} },
        } = res;

        return { data: section as Section };
      })
      .catch((e) => {
        throw new Error(`Error when calling getSection with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    try {
      let idChunks: string[][] | undefined;
      let sections: Section[] = [];
      let pagingMetadata: SectionsPagingMetadata | undefined;
      if (ids?.length) {
        idChunks = getEntityChunks(ids, MAX_NUMBER_OF_ENTITIES_FROM_SERVER);
        const sectionsInChunks = await Promise.all(
          idChunks.map(async (chunk) => {
            const { data } = await httpClient.request(
              listSections({
                sectionIds: chunk || [],
              })
            );
            return data.sections || [];
          })
        );
        sections.push(...sectionsInChunks.flatMap((section) => section as Section[]));
      } else {
        const { data } = await httpClient.request(listSections({ paging }));
        sections = data.sections as Section[];
        pagingMetadata = data.pagingMetadata;
      }
      return {
        data: (sortByCreatedDate(sections as (Section & { createdDate: Date })[]) as Section[]) || [],
        pagingMetadata,
      };
    } catch (error) {
      throw new Error(`Error when calling getSections- ${error}`);
    }
  },
});
