import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS, DASHBOARD_URLS } from '@wix/restaurants-consts';
import { addReferralToUrl } from './bi';
import type { BiReferral } from './bi';

export const openManageMenuDashboardPanel = (editorSDK: FlowEditorSDK | null, queryReferralInfo?: BiReferral) => {
  const url = addReferralToUrl(DASHBOARD_URLS.menusUrl, queryReferralInfo);
  if (editorSDK) {
    editorSDK.editor
      .openDashboardPanel(APP_DEF_IDS.menus, {
        url,
        closeOtherPanels: true,
      })
      .then(async () => {
        await editorSDK.document.application.livePreview.refresh('', {
          shouldFetchData: true,
          source: '',
        });
        await editorSDK.document.application.reloadManifest();
      });
  }
};
