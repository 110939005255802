import {
  restaurantsEditorPanelLoad,
  menusEditorElementsPanelCheckboxAction,
  menusEditorMenuLayoutPick,
  menusLiveSiteEditorPreviewNavigationClick,
  restaurantsEditorPanelEditAction,
  restaurantsEditorSitePagesAndMenuDeletePageAndApp,
  menusEditorMenuNavigationPanelAction,
} from '@wix/bi-logger-restaurants/v2';
import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  PlatformControllerFlowAPI,
  ReportError,
} from '@wix/yoshi-flow-editor';
import { MENU_WIDGET_NAME } from 'root/components/Menu/consts';
import { SECTION_WIDGET_NAME } from 'root/components/Section2/consts';
import MenuWidget from 'root/components/Menu/.component.json';
import SectionWidget from 'root/components/Section2/.component.json';
import { getSiteData, MENU_ELEMENTS_PANEL, MENUS_PRODUCT } from './bi';
import { MENU_WIDGET_PRESET_WITH_IMG_IDS } from './consts';
import type { ElementsPanelAction } from 'root/types';

export class BiReporter {
  private sitePageName?: string = '';
  private siteMetasiteId?: string = '';
  private sitePageId?: string = '';

  constructor(private biLogger: EditorScriptFlowAPI['bi'], private widgetInstanceId: string) {}

  async init({
    editorSDK,
    flowAPI,
    reportError,
  }: {
    editorSDK?: FlowEditorSDK;
    flowAPI?: PlatformControllerFlowAPI;
    reportError?: ReportError;
  }) {
    const { metaSiteId, pageName, pageId } = await getSiteData(editorSDK, flowAPI, reportError);
    this.siteMetasiteId = metaSiteId;
    this.sitePageName = pageName;
    this.sitePageId = pageId;
  }

  reportMenuWidgetPanelBi(origin: string, panelName: string) {
    this.biLogger?.report(
      restaurantsEditorPanelLoad({
        origin,
        panelName,
        product: 'menus',
        sitePageName: this.sitePageName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportSectionWidgetPanelBi(origin: string, panelName: string) {
    this.biLogger?.report(
      restaurantsEditorPanelLoad({
        origin,
        panelName,
        product: 'menus',
        sitePageName: this.sitePageName,
        widgetId: SectionWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: SECTION_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportElementCheckboxAction(action: ElementsPanelAction, elementName: string) {
    this.biLogger?.report(
      menusEditorElementsPanelCheckboxAction({
        sitePageName: this.sitePageName,
        widgetInstanceId: this.widgetInstanceId,
        widgetId: MenuWidget.id,
        widgetName: MENU_WIDGET_NAME,
        panelName: MENU_ELEMENTS_PANEL,
        msid: this.siteMetasiteId,
        pageId: this.sitePageId,
        action,
        elementName,
      })
    );
  }

  reportMenuLayoutPickBi(chosenPreset: string) {
    this.biLogger?.report(
      menusEditorMenuLayoutPick({
        allowImages: MENU_WIDGET_PRESET_WITH_IMG_IDS.includes(chosenPreset),
        layout: chosenPreset,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportMenusPanelAction({
    panelName,
    fullPath,
    value,
    firstLevel,
  }: {
    panelName: string;
    fullPath: string;
    value: string;
    firstLevel: string;
  }) {
    this.biLogger?.report(
      restaurantsEditorPanelEditAction({
        firstLevel,
        fullPath,
        value,
        panelName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        sitePageName: this.sitePageName,
        msid: this.siteMetasiteId,
        product: 'menus',
      })
    );
  }

  reportMenusHorizontalNavigationClickBi(numMenus: number, pageName: string | undefined, navigationType: string) {
    this.biLogger?.report(
      menusLiveSiteEditorPreviewNavigationClick({
        numMenus,
        pageName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        menuNavigation: navigationType,
      })
    );
  }

  reportPageDelete(origin: string) {
    this.biLogger?.report(
      restaurantsEditorSitePagesAndMenuDeletePageAndApp({
        msid: this.siteMetasiteId,
        origin,
        product: MENUS_PRODUCT,
      })
    );
  }

  reportMenuNavigationPanelAction(action: string) {
    this.biLogger?.report(
      menusEditorMenuNavigationPanelAction({
        action,
        pageId: this.sitePageId,
        sitePageName: this.sitePageName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
      })
    );
  }
}
