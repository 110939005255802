import type { EditorSDK } from '@wix/platform-editor-sdk';

export const baseUrlFromEditorUrl = (editorScriptUrl: string) => {
  const editorScriptUrlParts = editorScriptUrl.split('/');
  const staticsBaseUrl = editorScriptUrlParts.slice(0, editorScriptUrlParts.length - 1).join('/') + '/';
  return staticsBaseUrl;
};

const MAX_RETRIES = 10;
export const getAvailablePageUri = async (editorSDK: EditorSDK, pageUriSEO: string, numOfRetries = 0) => {
  const applicationPages = await editorSDK.pages.data.getAll('_token');
  let updatedUri = pageUriSEO;
  if (numOfRetries) {
    updatedUri += `-${numOfRetries}`;
  }
  const isUriExists = applicationPages.some((page) => page.pageUriSEO === updatedUri);
  while (isUriExists && numOfRetries < MAX_RETRIES) {
    updatedUri = await getAvailablePageUri(editorSDK, pageUriSEO, numOfRetries + 1);
    return updatedUri;
  }

  return updatedUri;
};
