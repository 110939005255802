import type { ComponentLayoutObject } from '@wix/platform-editor-sdk';
import type { MigrationComponent, MigrationLayout } from './types';

export const getBlocksLayout = ({
  component,
  isResponsive,
}: {
  component: MigrationComponent;
  isResponsive: boolean;
}) => {
  const layouts: MigrationLayout = {};

  if (!isResponsive) {
    layouts.layout = component.serialize.layout;
    layouts.mobileStructure = component.serialize.mobileStructure;
  } else {
    layouts.layout = component.serialize.layout;
    layouts.layouts = component.serialize.layouts ? copyLayouts(component.serialize.layouts) : undefined;
    layouts.scopedLayouts = component.serialize.scopedLayouts
      ? Object.fromEntries(
          Object.entries(component.serialize.scopedLayouts).map(([key, value]) => [key, copyLayouts(value)])
        )
      : undefined;
  }

  return layouts;
};

const copyLayouts = (layouts: Partial<ComponentLayoutObject>) => {
  return Object.fromEntries(Object.entries(layouts).filter(([key, _]) => key !== 'metaData'));
};
