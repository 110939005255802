import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { UNSELECTED_VARIANTS_COMPONENT_IDS } from './consts';
import { disableWidgetComponentSelection } from 'root/utils/manifestUtils';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder) => {
  widgetBuilder.set({
    displayName: 'Widget Varaint',
    nickname: 'VaraintWidget',
  });

  UNSELECTED_VARIANTS_COMPONENT_IDS.forEach((componentId) =>
    disableWidgetComponentSelection(widgetBuilder, componentId)
  );
};
