import { ZERO_PRICE_DISPLAY_OPTIONS, SHOW_PLACEHOLDER_IMAGE_OPTIONS } from 'root/components/Menu/consts';
import { DEFAULT_MENU_PRESET_ID } from './consts';
import type { ColumnsSectionSharedProps, WidgetsSharedProps } from 'root/types';

export const WIDGETS_SHARED_DEFAULT_DATA: WidgetsSharedProps = {
  preset: DEFAULT_MENU_PRESET_ID,
  shouldDisplayCurrency: true,
  shouldDisplayVariantCurrency: true,
  placeholderImage: undefined,
};

export const MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA: WidgetsSharedProps &
  Omit<ColumnsSectionSharedProps, 'sections'> = {
  ...WIDGETS_SHARED_DEFAULT_DATA,
  zeroPriceDisplayOption: ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_ALL,
  placeholderImageDisplayValue: SHOW_PLACEHOLDER_IMAGE_OPTIONS.NEVER,
  zeroPriceDisplaySpecificSectionIds: undefined,
  sectionsWithPlaceholderImageIds: undefined,
};

export const COLUMNS_SECTIONS_SHARED_DEFAULT_DATA: WidgetsSharedProps & ColumnsSectionSharedProps = {
  ...WIDGETS_SHARED_DEFAULT_DATA,
  ...MENU_COLUMNS_SECTIONS_SHARED_DEFAULT_DATA,
  sections: [],
};
