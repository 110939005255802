import type { ComponentRef, EditorSDK, PresetValue, StyleParam } from '@wix/platform-editor-sdk';
import {
  LIST_1_COLUMN_PRESET_ID,
  LIST_2_COLUMN_PRESET_ID,
  LIST_3_COLUMN_PRESET_ID,
  LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_PRESET_ID,
  LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID,
  SIDE_BY_SIDE_1_COLUMN_PRESET_ID,
  SIDE_BY_SIDE_2_COLUMN_PRESET_ID,
  GRID_PRESET_ID,
  CARD_MOBILE_PRESET_ID,
} from 'root/utils/consts';
import { getPresetName, Preset } from 'root/utils/OOI/consts';
import type { Design, FontObject, FullDesign, MigrationComponent, Styles, Theme } from './types';
import type { BreakpointRange, Pointer } from '@wix/document-services-types';
import { getStyles } from './styles';

const getPreset = (presetRes?: PresetValue): Pick<Design, 'preset' | 'columns'> => {
  switch (presetRes?.layout) {
    case LIST_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.Center,
      };
    case LIST_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 2,
      };
    case LIST_3_COLUMN_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 3,
      };
    case LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_1_COLUMN_WITH_IMAGE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID:
      return {
        preset: Preset.Columns,
        columns: 1,
      };
    case SIDE_BY_SIDE_1_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
      };
    case SIDE_BY_SIDE_2_COLUMN_PRESET_ID:
      return {
        preset: Preset.SideBySide,
        columns: 2,
      };
    case CARD_MOBILE_PRESET_ID:
      return {
        preset: Preset.Grid,
        columns: 1,
      };
    case GRID_PRESET_ID:
    default:
      return {
        preset: Preset.Grid,
        columns: 3,
      };
  }
};

const getResponsiveMobileVariant = (component: MigrationComponent) => {
  const breakpoints = Object.fromEntries(
    Object.entries(component.serialize.referredVariants ?? {}).filter(
      (variant): variant is [string, { pointer: Pointer; data: BreakpointRange }] => {
        return variant[1].data.type === 'BreakpointRange';
      }
    )
  );

  const minBreakpoint = Object.entries(breakpoints).reduce((variant1, variant2) => {
    const does1GreaterThan2 = (variant1[1].data.canvasSize ?? 10000) > (variant2[1].data.canvasSize ?? 10000);

    return does1GreaterThan2 ? variant2 : variant1;
  });

  return minBreakpoint[0];
};

export const getBlocksDesign = ({
  component,
  isResponsive,
  allFonts,
}: {
  component: MigrationComponent;
  isResponsive: boolean;
  allFonts: FontObject[];
}): FullDesign => {
  const desktopPreset = getPreset(component.serialize.presets);

  const variant = isResponsive ? getResponsiveMobileVariant(component) : 'MOBILE-VARIANT';
  const mobilePreset = getPreset(component.serialize.scopedPresets?.[variant]);
  const styles = getStyles({ component, allFonts });

  return {
    desktop: desktopPreset,
    mobile: mobilePreset,
    styles,
  };
};

const getStyleParamsFromDesign = (design: Design) => {
  const styleParams: StyleParam[] = [
    {
      key: 'preset',
      param: {
        value: design.preset,
      },
      type: 'number',
    },
  ];

  if (design.columns) {
    styleParams.push({
      key: `${getPresetName(design.preset)}NumOfColumns`,
      param: {
        value: design.columns,
      },
      type: 'number',
    });
  }

  return styleParams;
};

const getTextStyleParams = (theme: Theme, fontKey: string, colorKey: string): StyleParam[] => {
  const styles: StyleParam[] = [
    {
      key: fontKey,
      param: {
        value: {
          fontStyleParam: true,
          // @ts-expect-error
          family: theme.fontFamily,
          preset: theme.themeName ?? 'Custom',
          size: theme.fontSize ?? 16,
          style: {
            bold: theme.bold ?? false,
            italic: theme.italic ?? false,
            underline: theme.underline ?? false,
          },
        },
      },
      type: 'font',
    },
  ];

  if (theme.color) {
    styles.push({
      key: colorKey,
      param: {
        value: {
          // @ts-expect-error
          color: {
            name: theme.color,
          },
        },
      },
      type: 'color',
    });
  }

  return styles;
};

const getStyleParamsFromStyles = (styles: Styles) => {
  const styleParams: StyleParam[] = [];

  if (styles.menuBackgroundColor) {
    styleParams.push({
      key: 'menuBackgroundColor',
      param: {
        value: {
          // @ts-expect-error
          color: {
            name: styles.menuBackgroundColor.background ?? '',
          },
          opacity: parseFloat(styles.menuBackgroundColor.alpha ?? '1'),
        },
      },
      type: 'color',
    });
  }

  if (styles.menuName) {
    styleParams.push(...getTextStyleParams(styles.menuName, 'menuTitleTheme', 'menuTitleColor'));
  }

  if (styles.menuDescription) {
    styleParams.push(...getTextStyleParams(styles.menuDescription, 'menuDescriptionTheme', 'menuDescriptionColor'));
  }

  if (styles.sectionName) {
    styleParams.push(...getTextStyleParams(styles.sectionName, 'sectionTitleTheme', 'sectionTitleColor'));
  }

  if (styles.sectionDescription) {
    styleParams.push(
      ...getTextStyleParams(styles.sectionDescription, 'sectionDescriptionTheme', 'sectionDescriptionColor')
    );
  }

  if (styles.itemName) {
    styleParams.push(...getTextStyleParams(styles.itemName, 'itemTitleTheme', 'itemTitleColor'));
  }

  if (styles.itemDescription) {
    styleParams.push(...getTextStyleParams(styles.itemDescription, 'itemDescriptionTheme', 'itemDescriptionColor'));
  }

  if (styles.itemPrice) {
    styleParams.push(...getTextStyleParams(styles.itemPrice, 'itemPriceTheme', 'itemPriceColor'));
  }

  if (styles.variantName) {
    styleParams.push(...getTextStyleParams(styles.variantName, 'variantTitleTheme', 'variantTitleColor'));
  }

  if (styles.variantPrice) {
    styleParams.push(...getTextStyleParams(styles.variantPrice, 'variantPriceTheme', 'variantPriceColor'));
  }

  return styleParams;
};

export const applyOoiDesign = async ({
  editorSDK,
  ref,
  design,
  ooiStyleParams,
}: {
  editorSDK: EditorSDK;
  ref: ComponentRef;
  design: FullDesign;
  ooiStyleParams: StyleParam[];
}) => {
  const styleParams = [
    ...ooiStyleParams,
    ...getStyleParamsFromDesign(design.desktop),
    ...getStyleParamsFromStyles(design.styles),
  ];

  const mobileStyleParams = getStyleParamsFromDesign(design.mobile);

  const mobileSuffix = `▶︎m`;

  const mStyles = mobileStyleParams.map((param) => ({
    ...param,
    key: `${param.key}${mobileSuffix}`,
  }));

  await editorSDK.document.tpa.setStyleParams('', {
    compRef: ref,
    styleParams: [...styleParams, ...mStyles],
  });
};
