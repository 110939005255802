import type { FlowEditorSDK, PlatformControllerFlowAPI, ReportError } from '@wix/yoshi-flow-editor';

export const MENU_SETTINGS_PANEL = 'menu settings';
export const CHOOSE_MENUS_PANEL = 'choose menus';
export const DESIGN_MENU_PANEL = 'menu design';
export const MENU_ELEMENTS_PANEL = 'menu elements';
export const MENU_LAYOUT_CUSTOMIZATION_PANEL = 'menu layout customization';
export const MENU_PRESETS_PANEL = 'menu presets';
export const LABEL_STYLE_PANEL = 'label style';
export const MENU_ELEMENTS_ORIGIN = 'menu elements button';
export const MENU_SETTINGS_ORIGIN = 'menu settings button';
export const CHOOSE_MENUS_ORIGIN = 'choose menus button';
export const LABEL_STYLE_ORIGIN = 'label styles button';
export const MENU_LAYOUT_ORIGIN = 'menu layout button';
export const MENU_LAYOUT_CUSTOMIZATION_ORIGIN = 'menu layout customization button';
export const MENU_DESIGN_ORIGIN = 'menu design button';
export const NAVIGATION_PANEL = 'choose navigation';
export const NAVIGATION_PANEL_ORIGIN = 'menu choose navigation panel';
export const MIGRATION_PANEL = 'migration panel';
export const MIGRATION_PANEL_ORIGIN = 'migration button';

export const MENUS_PRODUCT = 'menus';
export const DELETE_PAGE_ORIGIN = 'pages panel';

export const ELEMENTS_PANEL_ACTION_CHECK = 'check';
export const ELEMENTS_PANEL_ACTION_UNCHECK = 'uncheck';

export const DASHBOARD_LOAD_REFERRAL_INFO = {
  manageMenus: 'manage-menus-editor',
  myBusiness: 'my-business-editor',
};

export const getHostingEnvironment = (environment: PlatformControllerFlowAPI['environment']) => {
  if (environment.isEditor) {
    return 'editor';
  } else if (environment.isEditorX) {
    return 'editor x';
  } else if (environment.isADI) {
    return 'adi';
  } else if (environment.isPreview) {
    return 'preview';
  } else if (environment.isMobile) {
    return 'mobile';
  } else if (environment.isViewer) {
    return 'viewer';
  } else {
    return undefined;
  }
};

export const getSitePage = async (pages?: FlowEditorSDK['pages']) => {
  const pageRef = await pages?.getCurrent('token');
  const sitePage = pageRef && (await pages?.getPageData('token', { pageRef }));
  return sitePage;
};

export const getSitePageName = async (pages?: FlowEditorSDK['pages']) => {
  const sitePage = await getSitePage(pages);
  return sitePage?.title;
};

export const getSiteData = async (
  editorSDK?: FlowEditorSDK,
  flowAPI?: PlatformControllerFlowAPI,
  reportError?: ReportError
): Promise<{
  pageName?: string;
  pageId?: string;
  metaSiteId?: string;
}> => {
  try {
    if (editorSDK) {
      const sitePage = await getSitePage(editorSDK.pages);
      return {
        pageName: sitePage?.title,
        pageId: sitePage?.id,
        metaSiteId: await editorSDK.info.getMetaSiteId(''),
      };
    } else {
      const sitePage = flowAPI?.controllerConfig?.wixCodeApi.site.currentPage;
      return {
        pageName: sitePage?.name,
        pageId: sitePage?.id,
        metaSiteId: flowAPI?.controllerConfig?.platformAPIs.bi?.metaSiteId,
      };
    }
  } catch (error) {
    // TODO: need to find a way to report error in editorSDK
    const errorHandler = reportError || flowAPI?.reportError;
    errorHandler?.(error as Error);

    return {};
  }
};
