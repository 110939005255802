import type { PopulatedMenu, Menu, Section, ShowcasePopulatedItem, PopulatedSection } from 'root/types';

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  populatedItems: ShowcasePopulatedItem[]
): PopulatedMenu => {
  const menuSections = menu.sectionIds
    ?.map((sectionId) => sections.find((section) => section.id === sectionId))
    ?.filter((section) => !!section && section.visible !== false);

  const sectionsWithItems = menuSections?.map((section) => {
    const sectionItems = section?.itemIds
      ?.map((itemId) => populatedItems.find((item) => item.id === itemId))
      ?.filter((item) => !!item && item.visible !== false) as ShowcasePopulatedItem[];

    return {
      ...section,
      items: sectionItems,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};
