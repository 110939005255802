import { chunkArray } from './chunkArray';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER } from 'root/utils/consts';
import type { Menu, Section, MenusEntity, BaseShowcasePopulatedItem } from 'root/types';
import type { GetAllResponse, IMenusHTTPClient, CursorPaging } from './types';

export const completeMissingEntities = async (
  menus: Menu[],
  sections: GetAllResponse<Section>,
  getSections: IMenusHTTPClient<Section>['getAll'],
  populatedItems: GetAllResponse<BaseShowcasePopulatedItem> | undefined,
  getPopulatedItems: IMenusHTTPClient<BaseShowcasePopulatedItem>['getAll'],
  maxNumberOfEntitiesToLoad?: CursorPaging['limit']
) => {
  const sectionIds = [...new Set(menus.flatMap((menu) => menu.sectionIds || []))];
  await completeMissingEntity(sectionIds, sections, getSections, maxNumberOfEntitiesToLoad);
  const itemIds = [...new Set(sections.data.flatMap((section) => section.itemIds || []))];
  await completeMissingEntity(itemIds || [], populatedItems, getPopulatedItems, maxNumberOfEntitiesToLoad);
};

export const completeMissingEntity = async <T extends MenusEntity>(
  ids: string[],
  entities: GetAllResponse<T> | undefined,
  getEntities: IMenusHTTPClient<T>['getAll'],
  maxNumberOfEntitiesToLoad: CursorPaging['limit']
) => {
  if (entities?.pagingMetadata?.hasNext) {
    const missingEntitiesIds = ids.reduce((acc: string[], id: string) => {
      const currEntity = entities.data.find((entity) => entity.id === id);
      return currEntity ? acc : [...acc, id];
    }, []);
    if (missingEntitiesIds.length > 0) {
      const missingEntitiesIdsChunks = chunkArray(
        missingEntitiesIds,
        maxNumberOfEntitiesToLoad || MAX_NUMBER_OF_ENTITIES_FROM_SERVER
      );
      const missingEntities = await Promise.all(
        missingEntitiesIdsChunks.map((missingIds: string[]) => getEntities({ ids: missingIds }))
      );
      entities.data.push(...missingEntities.flatMap((entity) => entity.data));
    }
  }
};
