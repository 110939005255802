import type { FlowEditorSDK, TFunction } from '@wix/yoshi-flow-editor';
import { type PageRef } from '@wix/platform-editor-sdk';
import { PARENT_APP_DEF_ID, MENUS_SHOWCASE_APP_DEF_ID } from 'root/utils/consts';
import type { ComponentStructure, PageData } from 'root/utils/OOI/consts';
import { PAGE_DATA, responsiveLayouts, widgetLayout } from 'root/utils/OOI/consts';
import { createWidgetDefinition, createSectionDefinition } from './createDefinitions';
import { setPagesState } from './setPagesState';
import { getAvailablePageUri } from 'root/utils/url';

const addWidgetAsPage = async ({
  editorSDK,
  appDefId,
  pageData,
  pageTitle,
  shouldAddMenuItem = false,
  shouldNavigateToPage,
  tpaAppId,
  isResponsive,
  isOptimusOrigin,
}: {
  editorSDK: FlowEditorSDK;
  appDefId: string;
  pageData: PageData;
  pageTitle: string;
  shouldAddMenuItem?: boolean;
  shouldNavigateToPage: boolean;
  tpaAppId: number;
  isResponsive: boolean;
  isOptimusOrigin?: boolean;
}): Promise<PageRef> => {
  const { pageId, widgetId, pageUriSEO: pageUri } = pageData;
  const pageUriSEO = isOptimusOrigin ? await getAvailablePageUri(editorSDK, pageUri) : pageUri;
  const hidePage = isOptimusOrigin;

  const tpaWidget: ComponentStructure = {
    componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
    data: {
      applicationId: `${tpaAppId}`,
      widgetId,
      appDefinitionId: appDefId,
      type: 'TPA',
    },
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: isResponsive ? undefined : widgetLayout,
    layouts: isResponsive ? responsiveLayouts : undefined,
  };

  const pageRefPromise = await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const pageRef = await editorSDK.document.pages.add('', {
      title: pageTitle,
      definition: {
        id: '',
        type: 'Page',
        componentType: 'mobile.core.components.Page',
        components: isResponsive
          ? [
              createSectionDefinition([
                createWidgetDefinition({
                  appDefinitionId: appDefId,
                  widgetId,
                  tpaAppId,
                }),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ]) as any,
            ]
          : [
              {
                anchor: {
                  id: '',
                  type: 'AnchorInfo',
                  name: 'Menus',
                },
                componentType: 'wysiwyg.viewer.components.ClassicSection',
                components: [tpaWidget],
                design: {
                  type: 'MediaContainerDesignData',
                  background: {
                    type: 'BackgroundMedia',
                  },
                },
              },
            ],
        data: {
          appDefinitionId: appDefId,
          managingAppDefId: PARENT_APP_DEF_ID,
          tpaPageId: pageId,
          pageUriSEO,
          hidePage,
          tpaApplicationId: tpaAppId,
        },
      },
      shouldAddMenuItem,
      shouldNavigateToPage,
    });

    return pageRef;
  });

  return pageRefPromise;
};

export const createMenuOOIPage = async ({
  editorSDK,
  t,
  tpaAppId,
  isResponsive,
  shouldNavigateToPage = true,
  isOptimusOrigin,
}: {
  editorSDK: FlowEditorSDK;
  t: TFunction;
  tpaAppId: number;
  isResponsive: boolean;
  shouldNavigateToPage?: boolean;
  isOptimusOrigin?: boolean;
}) => {
  const pageTitle = t(PAGE_DATA.title);
  const pageRef = await addWidgetAsPage({
    editorSDK,
    appDefId: MENUS_SHOWCASE_APP_DEF_ID,
    pageData: PAGE_DATA,
    pageTitle,
    shouldAddMenuItem: true,
    shouldNavigateToPage,
    tpaAppId,
    isResponsive,
    isOptimusOrigin,
  });
  await setPagesState({ editorSDK });

  return pageRef;
};
