import type { EditorSDK } from '@wix/platform-editor-sdk';
import { MENUS_SHOWCASE_APP_DEF_ID, PAGE_DATA } from 'root/utils/consts';
import type { ComponentStructure } from 'root/utils/OOI/consts';
import { WIDGET_IDS, widgetLayout, PAGE_DATA as OOI_PAGE_DATA } from 'root/utils/OOI/consts';
import { createWidgetDefinition } from 'root/utils/OOI/editorScript/shared/createDefinitions';
import { applyOoiDesign, getBlocksDesign } from './design';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { FontObject, MigrationComponent } from './types';
import { applyOoiSettings, getBlocksSettings } from './settings';
import { getBlocksLayout } from './layout';

const logger = console;

export const migrateByRef = async ({
  editorSDK,
  tpaAppId,
  component,
  isResponsive,
  flowAPI,
  reportError,
  allFonts,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  component: MigrationComponent;
  isResponsive: boolean;
  flowAPI: EditorScriptFlowAPI;
  reportError: (errorMessage: string) => void;
  allFonts: FontObject[];
}) => {
  if (component.ref.type === 'MOBILE') {
    try {
      await editorSDK.document.components.remove('', { componentRef: component.ref });

      return;
    } catch (e) {
      const errorMessage = `failed to remove mobile widget: ${(e as Error).message}`;
      logger.error(errorMessage);
      reportError(errorMessage);

      return;
    }
  }

  const blocksDesign = getBlocksDesign({ component, isResponsive, allFonts });

  const blockSettings = getBlocksSettings({ component });

  const blocksLayout = getBlocksLayout({ component, isResponsive });

  await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const ancestors = await editorSDK.document.components.getAncestors('', { componentRef: component.ref });
    const parent = ancestors[0];

    const tpaWidget: ComponentStructure = isResponsive
      ? createWidgetDefinition({
          appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
          widgetId: WIDGET_IDS.menus,
          tpaAppId,
          overrides: {
            parent: parent.id,
            layout: blocksLayout.layout,
            layouts: blocksLayout.layouts,
            scopedLayouts: blocksLayout.scopedLayouts,
            layoutResponsive: undefined,
          },
        })
      : {
          componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
          data: {
            applicationId: `${tpaAppId}`,
            widgetId: WIDGET_IDS.menus,
            appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
            type: 'TPA',
          },
          parent: parent.id,
          skin: 'wysiwyg.viewer.skins.TPASectionSkin',
          layout: blocksLayout.layout ?? widgetLayout,
          mobileStructure: blocksLayout.mobileStructure ? { layout: blocksLayout.mobileStructure.layout } : undefined,
          layouts: undefined,
        };

    let ooiRef;

    try {
      await editorSDK.document.components.remove('', { componentRef: component.ref });

      ooiRef = await editorSDK.document.components.addAndAdjustLayout('', {
        componentDefinition: tpaWidget,
        pageRef: parent,
      });

      await editorSDK.document.pages.data.update('', {
        pageRef: { id: component.serialize.metaData?.pageId ?? '', type: 'DESKTOP' },
        data: {
          tpaPageId: OOI_PAGE_DATA.pageId,
        },
      });

      try {
        const { ooiStyleParams } = await applyOoiSettings({
          editorSDK,
          ref: ooiRef,
          settings: blockSettings,
          flowAPI,
        });
        await applyOoiDesign({ editorSDK, ref: ooiRef, design: blocksDesign, ooiStyleParams });
      } catch (e) {
        const errorMessage = `failed to apply styles and settings: ${(e as Error).message}`;
        logger.error(errorMessage);
        reportError(errorMessage);
      }
    } catch (e) {
      const errorMessage = `failed to switch to ooi widget: ${(e as Error).message}`;
      logger.error(errorMessage);
      reportError(errorMessage);
    }
  });
};

export const migrateAll = async ({
  editorSDK,
  tpaAppId,
  isResponsive,
  flowAPI,
  reportError,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  isResponsive: boolean;
  flowAPI: EditorScriptFlowAPI;
  reportError: (errorMessage: string) => void;
}) => {
  const allRefComponents = await editorSDK.document.components.refComponents.getAllAppRefComponents('');
  const components: MigrationComponent[] = await Promise.all(
    allRefComponents.map(async (ref) => {
      return { ref, serialize: await editorSDK.document.components.serialize('', { componentRef: ref }) };
    })
  );

  const fonts = await editorSDK.document.fonts.getFontsOptions('');
  const allFonts: FontObject[] = fonts.reduce(
    (acc: FontObject[], langFonts) => [...acc, ...(langFonts.fonts as FontObject[])],
    []
  );

  const blocksComponents = components.filter((component) => component.serialize.data.widgetId === PAGE_DATA.widgetId);

  await editorSDK.editor.selection.deselectComponents('', {
    compsToDeselect: blocksComponents.map((comp) => comp.ref),
  });

  await Promise.all(
    blocksComponents.map((component) =>
      migrateByRef({ editorSDK, tpaAppId, component, isResponsive, flowAPI, reportError, allFonts })
    )
  );
  // @ts-expect-error
  await editorSDK.document.tpa.app.refreshApp('', {
    appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
  });
};
