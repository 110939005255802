import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';
import { SECTION_WIDGET_NAME } from './consts';
import { COLUMNS_SECTIONS_SHARED_DEFAULT_DATA } from 'root/utils/widgetsDefaultData';

export default createBlocksModel({
  widgetName: SECTION_WIDGET_NAME,
  props: {
    data: {
      // TODO: fix this when https://wix.slack.com/archives/CKDB50KE2/p1668693127598739 done
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: {} as any,
      defaultValue: COLUMNS_SECTIONS_SHARED_DEFAULT_DATA,
    },
  },
  methods: {
    // Here you can define your widget public props
  },
  events: {
    // Here you can define events your widget will subscribe to
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
  },
});
