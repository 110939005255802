import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import type { ComponentRef, EditorReadyOptions, EditorSDK, EditorType } from '@wix/platform-editor-sdk';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
// eslint-disable-next-line no-restricted-imports
import blocksWidgetDescriptor from '../../blocks-widget-descriptor.json';

export const getWidgetProps = async (editorSDK: EditorSDK | null, componentRef: ComponentRef) => {
  const widgetProps = await editorSDK?.document.application.appStudioWidgets.props.get('', {
    widgetRef: componentRef,
  });
  return widgetProps;
};

export const setWidgetProps = async (
  editorSDK: EditorSDK | null,
  componentRef: ComponentRef,
  newProps: WidgetProps
) => {
  await editorSDK?.document.application.appStudioWidgets.props.set('', {
    widgetRef: componentRef,
    newProps,
  });
};

export const isMobileViewport = async (editorSDK: EditorSDK) => {
  const editorMode = await editorSDK.info.getCurrentViewport();
  return editorMode.type === 'MOBILE';
};

export const getIsStudio = (context: EditorReadyOptions) => {
  return context.origin?.subType === 'STUDIO';
};

export const getBlocksPanelOpener = async (
  editorSDK: EditorSDK,
  editorType: EditorType,
  essentials: EditorScriptFlowAPI['essentials']
) => {
  const panelApiFactory = new PanelsApiFactory();
  const panelsApi = await panelApiFactory.createPanelsApi({
    editorSDK,
    editorType,
    essentials,
  });

  // TODO: remove when the editor can take app revision from url
  const appRevision = Object.values(blocksWidgetDescriptor)[0].revision.toString();
  return async (panelName: string, componentRef: ComponentRef, title?: string) => {
    await panelsApi.openBlocksPanel(panelName, componentRef, {
      overrides: {
        revision: appRevision,
        title,
      },
    });
  };
};
